import React from "react"
import { LocalizedLink } from "./localized_link"

const WinesMenu = ({ digitalQrMode, allPrismicWinesection, layout }) => {
  return (
    <div className="flex-1 pl-6 relative group/main h-[88px] inline-block">
      <LocalizedLink to={digitalQrMode ? "/wines/?digitalQr" : "/wines/"}>
        <span className="link cursor-default">
          {layout.header_wine_link_text}
        </span>
      </LocalizedLink>
      <div className="bg-[#fdfdfd] absolute top-[72px] left-6 !flex-col shadow-md hidden group-hover/main:flex invisible group-hover/main:visible opacity-0 group-hover/main:opacity-100">
        {(allPrismicWinesection ?? []).map(({ node }) => {
          return (
            <div className="relative group/item">
              <LocalizedLink
                to={
                  digitalQrMode
                    ? `/wines/${node?.data?.slug}?digitalQr`
                    : `/wines/${node?.data?.slug}`
                }
                style={{ padding: "4px 8px", lineHeight: 1.5, fontSize: 20 }}
                key={node?.data?.slug}
              >
                {node.data.menu_title?.text}
              </LocalizedLink>
              <div className="bg-[#fdfdfd] absolute top-0 left-full !flex-col shadow-md hidden group-hover/item:flex invisible group-hover/item:visible opacity-0 group-hover/item:opacity-100">
                {node?.data?.sections?.map(item => {
                  const menuSection = item.section.document[0]?.data

                  return (
                    <LocalizedLink
                      to={
                        digitalQrMode
                          ? `/wines/${node?.data?.slug}?digitalQr#${menuSection?.id}`
                          : `/wines/${node?.data?.slug}/#${menuSection?.id}`
                      }
                      // to={`#${menuSection?.id}`}
                      style={{
                        padding: "4px 8px",
                        lineHeight: 1.5,
                        fontSize: 20,
                      }}
                      key={menuSection?.id}
                    >
                      {menuSection?.title}
                    </LocalizedLink>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default WinesMenu
