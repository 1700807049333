import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { useMemo, useState } from "react"
import backIcon from "../assets/back.png"
import closeIcon from "../assets/close.png"
import { scrollWindowTo } from "../utils"
import isMobile from "../utils/is_mobile"
import { LocalizedLink } from "./localized_link"

const Drawer = ({
  layout,
  path,
  allPrismicWinesection,
  digitalQrMode,
  onClose,
}) => {
  const [winesOpen, setWinesOpen] = useState(false)
  const [selectedWine, setSelectedWine] = useState(null)

  const sections = useMemo(() => {
    return (
      allPrismicWinesection?.find(node => {
        return node.node.data.slug === selectedWine
      })?.node?.data?.sections ?? []
    )
  }, [selectedWine])

  return (
    <div className="fixed top-0 left-0 z-[110] bg-white w-full h-full flex !flex-col gap-3 overflow-y-auto">
      <div className="relative flex flex-row items-center mt-5">
        <div className="image">
          <Img fluid={layout.header_image.localFile.childImageSharp.fluid} />
        </div>
        <span className="absolute right-6 -top-1" onClick={onClose}>
          <img src={closeIcon} />
        </span>
      </div>
      {!winesOpen && (
        <div className="flex-1 flex-col items-center pt-8">
          {!["/", "/en/"].includes(path) && !digitalQrMode && (
            <LocalizedLink
              to="/"
              style={{
                fontSize: 28,
                textAlign: "center",
                width: "100%",
                display: "block",
                lineHeight: 2.5,
              }}
              onClick={onClose}
            >
              {layout.header_home_link_text}
            </LocalizedLink>
          )}
          <LocalizedLink
            to={digitalQrMode ? "/menu/?digitalQr" : "/menu"}
            style={{
              fontSize: 28,
              textAlign: "center",
              width: "100%",
              display: "block",
              lineHeight: 2.5,
            }}
            onClick={onClose}
          >
            {layout.header_menu_link_text}
          </LocalizedLink>
          <p
            className="link"
            style={{
              fontSize: 28,
              textAlign: "center",
              width: "100%",
              display: "block",
              lineHeight: 2.5,
            }}
            onClick={() => {
              setWinesOpen(true)
            }}
          >
            {layout.header_wine_link_text}
          </p>
          {!digitalQrMode && (
            <span
              onClick={() => {
                onClose()
                scrollWindowTo("#footer-where", isMobile() ? 96 : 0)
              }}
              className="link"
              style={{
                fontSize: 28,
                textAlign: "center",
                width: "100%",
                display: "block",
                lineHeight: 2.5,
              }}
            >
              {layout.header_where_link_text}
            </span>
          )}
          {!digitalQrMode && (
            <span
              onClick={() => {
                onClose()
                scrollWindowTo("#contact-where")
              }}
              className="link accent"
              style={{
                fontSize: 28,
                textAlign: "center",
                width: "100%",
                display: "block",
                lineHeight: 2.5,
              }}
              children={layout.header_contact_us_link_text}
            />
          )}
        </div>
      )}
      {winesOpen && selectedWine === null && (
        <div className="flex-1 flex-col items-center pt-8 relative">
          {(allPrismicWinesection ?? [])?.map((node, index) => {
            return (
              <span
                onClick={() => {
                  setSelectedWine(node?.node?.data?.slug)
                }}
                className="link"
                style={{
                  fontSize: 28,
                  textAlign: "center",
                  width: "100%",
                  display: "block",
                  lineHeight: 2.5,
                }}
                key={index}
              >
                {node?.node?.data?.menu_title?.text}
              </span>
            )
          })}
          <span
            className="absolute left-2 top-[52px]"
            onClick={() => {
              setWinesOpen(false)
            }}
          >
            <img src={backIcon} />
          </span>
        </div>
      )}
      {selectedWine !== null && (
        <div className="flex-1 flex-col items-center pt-8 relative">
          {(sections ?? [])?.map((section, index) => {
            const item = section.section.document[0]?.data
            return (
              <LocalizedLink
                to={
                  digitalQrMode
                    ? `/wines/${selectedWine}?digitalQr#${item?.id}`
                    : `/wines/${selectedWine}/#${item?.id}`
                }
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  width: "100%",
                  display: "block",
                  lineHeight: 2.5,
                }}
                key={item.id}
                onClick={() => {
                  setSelectedWine(null)
                  setWinesOpen(false)
                  onClose()
                }}
              >
                {item?.title}
              </LocalizedLink>
            )
          })}
          <span
            className="absolute left-2 top-[52px]"
            onClick={() => {
              setSelectedWine(null)
            }}
          >
            <img src={backIcon} />
          </span>
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  query DrawerQuery {
    close: file(relativePath: { eq: "close.png" }) {
      childImageSharp {
        fluid(maxWidth: 24, quality: 95, pngCompressionSpeed: 1) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Drawer
